<template>
    <v-card outlined>
        <v-card-title class="pl-8 block-title">
            <v-row>
                <v-col class="mt-n4 justify-start text-left">
                    <v-switch inset color="#4E87DD" hide-details v-model="breakTimeUseYn" true-value="Y" false-value="N" @change="changeBreakTimeUseYn">
                        <template v-slot:prepend>
                            <span style="padding-top:2px; color:#333333; font-size:20px; width:100px;">휴게시간</span>
                        </template>
                        <template v-slot:label>
                            <span style="margin-left: 10px; font-size: 17px; font-weight: normal; color: #919191;">(영업시간 내 통화불가 시간을 설정, 점심시간이나 브레이크 타임을 안내)</span>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="2" class="mt-n2 pt-4 d-flex justify-end">
                    <v-btn v-if="unUsedBreakTimeFlag" large text class="text-center text-h5" color="white" style="background-color: #00bfa5; height: 40px; opacity: 0.3;">
                        <v-icon left dark size="20">
                            mdi-plus-circle
                        </v-icon>
                        <span style="font-size: 25px;">시간 추가</span>
                    </v-btn>
                    <v-btn v-else large text class="text-center text-h5" color="white" style="background-color: #00bfa5; height: 40px;" @click="addBreakTime">
                        <v-icon left dark size="20">
                            mdi-plus-circle
                        </v-icon>
                        <span style="font-size: 25px;">시간 추가</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pb-0 pl-0 pr-0">
            <v-card :disabled="unUsedBreakTimeFlag" class="pa-0 ma-0 rounded-0" style="box-shadow: none;">
                <v-card-text style="border: 0px;">
                    <v-row no-gutters>
                        <v-col>
                            <v-row class="text-h6 ml-2 mb-2">
                                <v-col style="color:#999999;">※ 해당 시간에는 콜백요청만 받을 수 있습니다.</v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <template v-for="(breakTime, idx) in dispBreakTimes">
                                <v-divider v-if="idx != 0 && idx < dispBreakTimes.length"></v-divider>
                                <v-card class="mt-3 ma-3" style="border: 0px; box-shadow: none;" :style="breakTimeCardStyle(breakTime)">
                                    <v-card-text class="mt-3">
                                        <v-row>
                                            <v-col cols="1" class="d-flex justify-end text-right" style="font-size: 20px; display: flex; align-items: center;">시간</v-col>
                                            <v-col cols="4" class="d-flex justify-start">
                                                <v-card outlined>
                                                    <v-card-text>
                                                        <v-row style="display: flex; align-items: center;">
                                                            <v-col cols="2" class="d-flex justify-center ma-0 pa-0" style="max-width: 85px;">
                                                                <v-btn class="ma-0 pa-0" style="font-size: 20px;" text @click="changeBreakTime(breakTime, 'break_start_hour', 'change_start_hour_flag')">{{ breakTime.break_start_hour }}</v-btn>
                                                                <template v-if="breakTime.change_start_hour_flag">
                                                                    <v-list
                                                                        dense
                                                                        outlined
                                                                        class="text-center mt-10 overflow-y-auto" max-height="250" min-width="75"
                                                                        :style="styleVListComp">
                                                                        <v-list-item-group
                                                                            color="primary"
                                                                            class="mt-n2 mb-n3"
                                                                            v-model="selectedBreakTime.value"
                                                                        >
                                                                            <template v-for="(hour, idx) in hours">
                                                                                <v-list-item
                                                                                    :key="idx"
                                                                                    :value="hour"
                                                                                    :id="hour"
                                                                                    @click="setBreakTime(breakTime, 'break_start_hour', hour, 'change_start_hour_flag')"
                                                                                >
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title v-text="hour"></v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </template>
                                                                        </v-list-item-group>
                                                                    </v-list>
                                                                </template>
                                                            </v-col>
                                                            <v-col class="ma-0 pa-0 font-weight-bold d-flex justify-center" style="max-width: 10px;"><span style="font-size: 20px;">:</span></v-col>
                                                            <v-col cols="2" class="ma-0 pa-0 d-flex justify-center" style="max-width: 85px;">
                                                                <v-btn class="ma-0 pa-0" style="font-size: 20px;" text @click="changeBreakTime(breakTime, 'break_start_min', 'change_start_min_flag')">{{ breakTime.break_start_min }}</v-btn>
                                                                <template v-if="breakTime.change_start_min_flag">
                                                                    <v-list
                                                                        dense
                                                                        outlined
                                                                        class="text-center mt-10 overflow-y-auto" max-height="250" min-width="75"
                                                                        :style="styleVListComp">
                                                                        <v-list-item-group
                                                                            color="primary"
                                                                            class="mt-n2 mb-n3"
                                                                            v-model="selectedBreakTime.value"
                                                                        >
                                                                            <template v-for="(min, idx) in minutes">
                                                                                <v-list-item
                                                                                    :key="idx"
                                                                                    :value="min"
                                                                                    :id="min"
                                                                                    @click="setBreakTime(breakTime, 'break_start_min', min, 'change_start_min_flag')"
                                                                                >
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title v-text="min"></v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </template>
                                                                        </v-list-item-group>
                                                                    </v-list>
                                                                </template>
                                                            </v-col>
                                                            <v-col class="ma-0 pa-0 font-weight-bold d-flex justify-center" style="max-width: 10px;"><span class="d-flex justify-center" style="font-size: 20px;">~</span></v-col>
                                                            <v-col cols="2" class="ma-0 pa-0 d-flex justify-center" style="max-width: 85px;">
                                                                <v-btn class="ma-0 pa-0" style="font-size: 20px;" text @click="changeBreakTime(breakTime, 'break_end_hour', 'change_end_hour_flag')">{{ breakTime.break_end_hour }}</v-btn>
                                                                <template v-if="breakTime.change_end_hour_flag">
                                                                    <v-list
                                                                        dense
                                                                        outlined
                                                                        class="text-center mt-10 overflow-y-auto" max-height="250" min-width="75"
                                                                        :style="styleVListComp">
                                                                        <v-list-item-group
                                                                            color="primary"
                                                                            class="mt-n2 mb-n3"
                                                                            v-model="selectedBreakTime.value"
                                                                        >
                                                                            <v-list-item
                                                                                v-for="(hour, idx) in hours"
                                                                                :key="idx"
                                                                                :value="hour"
                                                                                :id="hour"
                                                                                @click="setBreakTime(breakTime, 'break_end_hour', hour, 'change_end_hour_flag')"
                                                                            >
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title v-text="hour"></v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-list-item-group>
                                                                    </v-list>
                                                                </template>
                                                            </v-col>
                                                            <v-col class="ma-0 pa-0 font-weight-bold d-flex justify-center" style="max-width: 10px;"><span style="font-size: 20px;">:</span></v-col>
                                                            <v-col cols="2" class="ma-0 pa-0 d-flex justify-center" style="max-width: 85px;">
                                                                <v-btn class="ma-0 pa-0" style="font-size: 20px;" text @click="changeBreakTime(breakTime, 'break_end_min', 'change_end_min_flag')">{{ breakTime.break_end_min }}</v-btn>
                                                                <template v-if="breakTime.change_end_min_flag">
                                                                    <v-list
                                                                        dense
                                                                        outlined
                                                                        class="text-center mt-10 overflow-y-auto" max-height="250" min-width="75"
                                                                        :style="styleVListComp">
                                                                        <v-list-item-group
                                                                            color="primary"
                                                                            class="mt-n2 mb-n3"
                                                                            v-model="selectedBreakTime.value"
                                                                        >
                                                                            <v-list-item
                                                                                v-for="(min, idx) in minutes"
                                                                                :key="idx"
                                                                                :value="min"
                                                                                :id="min"
                                                                                @click="setBreakTime(breakTime, 'break_end_min', min, 'change_end_min_flag')"
                                                                            >
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title v-text="min"></v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-list-item-group>
                                                                    </v-list>
                                                                </template>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col v-show="breakTime.error.flag && breakTime.error.type == 'TIME'" class="red--text" style="display: flex; align-items: center;">* {{ breakTime.error.msg }}</v-col>
                                            <v-col style="display: flex; align-items: center;" class="justify-end">
                                                <v-btn icon v-if="idx != 0" class="mr-5" @click="removeBreakTime(idx, breakTime)"><v-icon large>mdi-delete-outline</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-n3">
                                            <v-col cols="1" class="d-flex justify-end text-right" style="font-size: 20px; display: flex; align-items: center;">멘트</v-col>
                                            <v-col>
                                                <v-text-field
                                                    placeholder="(필수) 고객에게 안내할 내용을 입력해주세요."
                                                    outlined hide-details dense
                                                    style="background-color:#FFFFFF;"
                                                    v-model="breakTime.break_ment"
                                                >
                                                    <template v-slot:append v-if="breakTime.error.flag && breakTime.error.type == 'MENT'">
                                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                                            {{ breakTime.error.msg }}
                                                        </div>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import Vue from 'vue'
import {mapState} from 'vuex'

export default {
    name: 'svc_voice_intro_break_time',
    components: {
        'popupBreakTime': () => import('@/components/svc/voice_intro/popup_break_time.vue')
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        breakTimeUseYn: 'N',
        hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        minutes: ['00', '10', '20', '30', '40', '50'],
        selectedBreakTime: {
            'idx': 99,
            'keyFlag': '',
            'value': ''
        },
        styleVList: {
            width: '60px',
            position: 'absolute',
            zIndex: '198',
            boxShadow: '0 0 15px 2px rgba(0,0,0,0.07)',
            borderRadius: '5px',
            overscrollBehaviorY: 'contain',
            scrollSnapType: 'y mandatory'
        }
    }),
    computed: {
        ...mapState('svcVoiceIntro', {
            breakTimeConf: state => state.breakTimeConf
        }),
        dispBreakTimes() {
            let ret = []

            this.breakTimeConf.breakTimes.forEach((item, idx) => {
                ret.push(item)
            })

            return ret
        },
        unUsedBreakTimeFlag() {
            return this.breakTimeConf.breakTimeUseYn == 'N' ? true : false
        },
        styleVListComp() {
            let ret = this.styleVList
            if (this.selectedBreakTime.idx != 99) {
                ret.scrollSnapType = 'y mandatory'
            }
            return ret
        }
    },
    created() {
    },
    mounted() {
        this.reload()
    },
    methods: {
        reload() {
            this.breakTimeUseYn = this.breakTimeConf.breakTimeUseYn

            if (!this.breakTimeConf.breakTimes || this.breakTimeConf.breakTimes.length == 0) {
                this.addBreakTime()
            }
        },
        apply() {
        },
        changeBreakTimeUseYn() {
            this.$store.commit('svcVoiceIntro/setBreakTimeUseYn', this.breakTimeUseYn)
        },
        addBreakTime() {
            if (this.breakTimeConf.breakTimes.length >= 5) {
                this.$store.dispatch('openAlert', {message: '휴게시간은 최대 5개까지 등록 가능합니다.',})
            } else {
                this.$store.commit('svcVoiceIntro/addBreakTime', {})
            }
        },
        removeBreakTime(idx) {
            this.breakTimeConf.breakTimes.splice(idx, 1)
        },
        changeBreakTime(obj, key, keyFlag) {
            // 동일한 휴게시간 영역
            if (this.selectedBreakTime.idx == obj.idx) {
                // 선택된 플래그 값이 true 이면 동일한 셀렉트 박스를 선택한 것으로 판단하여 초기화
                if (this.selectedBreakTime.keyFlag == keyFlag) {
                    obj[keyFlag] = false
                    this.selectedBreakTime = Object.assign({}, {'idx': 99, 'keyFlag': '', 'value': ''})
                } else {
                    obj[this.selectedBreakTime.keyFlag] = false
                    obj[keyFlag] = true
                    this.selectedBreakTime = Object.assign({}, {'idx': obj.idx, 'keyFlag': keyFlag, 'value': obj[key]})
                }
            } else {
                // 기존 선택 된 휴게시간이 있으면 숨김 처리
                if (this.selectedBreakTime.keyFlag != '') {
                    this.breakTimeConf.breakTimes[this.selectedBreakTime.idx][this.selectedBreakTime.keyFlag] = false
                }

                obj[keyFlag] = true
                this.selectedBreakTime = Object.assign({}, {'idx': obj.idx, 'keyFlag': keyFlag, 'value': obj[key]})
            }

            this.$nextTick(() => {
                if (document.getElementById(this.selectedBreakTime.value)) {
                    document.getElementById(this.selectedBreakTime.value).style.scrollSnapAlign = 'start'
                    setTimeout(() => {
                        this.styleVList.scrollSnapType = ''
                    }, 300)
                }
            })
        },
        setBreakTime(obj, key, value, keyFlag) {
            obj[key] = value
            obj[keyFlag] = !obj[keyFlag]
            this.selectedBreakTime = Object.assign({}, {'idx': 99, 'keyFlag': '', 'value': ''})
        },
        breakTimeCardStyle(v) {
            return v.error.flag ? 'border: 1px solid red' : ''
        }
    }
}
</script>